import { css } from 'styled-components'

const scrollbarDefaultStyles = css`
  ::-webkit-scrollbar {
    width: ${({ theme: { spacing } }) => spacing.xs};
    margin-right: ${({ theme: { spacing } }) => spacing(2.5)};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-right: ${({ theme: { spacing } }) => spacing(2.5)};
    border-radius: ${({ theme: { spacing } }) => spacing(4.5)};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { colors } }) => colors.neutral.main};
    margin-right: ${({ theme: { spacing } }) => spacing(2.5)};
    border-radius: ${({ theme: { spacing } }) => spacing(4.5)};
  }

  scrollbar-color: ${({ theme: { colors } }) =>
    `${colors.neutral.main} transparent`};
  scrollbar-width: thin;
`

export default scrollbarDefaultStyles
